@import "../../mixins.scss";

.services-section {
  text-align: center;
  padding: 0 10%;
  .label {
    @include label();
    color: var(--primary-color);
  }
  h3 {
    @include heading2();
    color: var(--primary-text);
    margin: 15px 0 -60px 0;
  }
  .all-cards {
    width: 100%;
    @include flex();
    flex-wrap: wrap;
    .service-card {
      max-width: 300px;
      max-width: 300px;
      position: relative;
      margin: 20px 20px 100px 20px;

      img {
        border-radius: 30px;
        border: none;
        transition: var(--basic);
      }

      .content {
        position: absolute;
        bottom: -90px;
        left: 0;
        right: 0;
        min-height: 200px;
        // max-height: 200px;
        min-width: 80%;
        max-width: 80%;
        background: #fff;
        border: none;
        border-radius: 30px;
        margin: auto;
        padding: 0 15px 10px 15px;
        transition: var(--basic);

        img {
          width: 70px;
          height: auto;
          margin-top: -35px;
        }
        .title {
          @include label();
          font-size: 1.6rem;
          color: var(--secondary-text);
          margin-top: 10px;
        }
        .desc {
          @include para($fw: 400);
          color: var(--secondary-text);
          margin: 7px 0;
          display: -webkit-box;
          -webkit-line-clamp: 4; /* Number of lines */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        a {
          @include para($fw: 600);
          font-size: 1.3rem;
          color: var(--primary-color);
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &:hover {
        .content {
          background-color: var(--primary-color);
          bottom: -85px;

          img {
            transform: rotate(360deg);
          }

          .title,
          .desc {
            color: var(--primary-text);
          }
          a {
            color: var(--secondary-text);
          }
        }
      }

      @media (min-width: 1270px) {
        &:nth-child(2) {
          margin-top: 100px;
        }
        &:nth-child(3) {
          margin-top: 200px;
        }
        &:nth-child(4) {
          margin-top: -150px;
        }
        &:nth-child(5) {
          margin-top: -60px;
        }
        &:nth-child(6) {
          margin-top: 40px;
        }
      }

      @media (min-width: 1700px) {
        &:nth-child(2) {
          margin: 20px 20px 100px 20px;
        }
        &:nth-child(3) {
          margin: 20px 20px 100px 20px;
        }
        &:nth-child(4) {
          margin: 20px 20px 100px 20px;
        }
        &:nth-child(5) {
          margin: 20px 20px 100px 20px;
        }
        &:nth-child(6) {
          margin: 20px 20px 100px 20px;
        }
      }
    }
  }

  @media (max-width: 1270px) {
    .all-cards {
      margin: 90px auto;
    }
  }

  @media (min-width: 1700px) {
    h3 {
      margin-bottom: 30px;
    }
    .all-cards {
      margin-bottom: 80px;
    }
  }
}
